.partners {

	padding: 60px 0;
	background-color: $color-black;

	@include at-least-tablet {
		padding: 118px 0 72px 0;
	}

	&__container {
		position: relative;
		max-width: 1000px;
		margin: auto;
		padding: 0 22px;

		@include at-least-desktop {
			margin-left: 20%;
		}
	}

	&__title {
		color: white;
		@include font-secondary;

		@include at-least-tablet {
			font-size: 46px;
		}

	}

	&__tabs {
		text-align: right;

		/*@include at-least-desktop {
			margin-right: -100px;
		}*/

		@include at-least-desktop {
			margin-top: -68px;
		}
	}

	&__group {
		width: 100%;
		height: 0;
		overflow: hidden;

		&.active {
			height: auto;
		}

		&__name {
			display: inline-block;
			color: $color-dark-gray;
			padding: 5px 7px;
			position: relative;
			font-size: 20px;
			transition: color $transition-timing $transition-animation;

			&:hover {
				color: $color-main;
				&:before {
					width: calc(100% - 30px);
				}
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 7px;
				right: 7px;
				bottom: 0;
				width: calc(100% - 14px);
				height: 3px;
				background-color: $color-dark-gray;
				transition: all $transition-timing $transition-animation;
			}

			&.active {
				color: white;
				&:before {
					background-color: white;
				}
			}
		}

	}
}

.categories {

	&__tabs {
		position: relative;
		text-align: right;
		z-index: 3;

		/*@include at-least-desktop {
			margin-right: -100px;
		}*/

		&__name {
			display: inline-block;
			padding: 18px 7px 7px 7px;
			color: $color-dark-gray;
			font-size: 16px;
			transition: color $transition-timing $transition-animation;

			&:hover {
				color: $color-main;
			}

			&.active {
				color: white;
			}
		}
	}
	&__slider {
		//display: none;
		width: 100%;
		height: 0;
		overflow: hidden;

		&.active {
			//display: block;
			padding-top: 60px;
			padding-bottom: 40px;
			height: auto;

			@include at-least-large {
				padding-top: 100px;
				padding-bottom: 70px;
			}

		}

		&__item {
			display: block;
			position: relative;
			height: 200px;
			outline: none;
			&:focus {
				outline: none;
			}
			> img {
				max-height: 100%;
				max-width: 90%;
				@include absolute-center;
			}
		}
	}
}

.js-slider {

	@include at-least-large {
		top: -40px;
	}

	.slick-arrow {
		position: absolute;
		bottom: 0;
		width: 20px;
		height: 20px;
		cursor: pointer;
		z-index: 10;
		> svg {
			pointer-events: none;
			width: 6px;
			height: 12px;
			fill: white;
			@include absolute-center;
		}

		&.prev {
			right: 20px;
		}
		&.next {
			right: 0;
		}
		&.slick-disabled {
			cursor: auto;
			> svg {
				fill: $color-dark;
			}
		}
	}

	.slick-dots {
		list-style: none;
		margin: 0;
		padding: 0;
		position: absolute;
		left: 0;
		right: 50px;
		bottom: 8px;
		z-index: 10;
		display: flex;

		li {
			display: inline-block;
			width: 100%;

			button {
				display: block;
				text-indent: -9999px;
				height: 3px;
				background-color: $color-dark;
				border: 0;
				width: 100%;
			}

			&.slick-active {
				button {
					background-color: white;
				}
			}
		}
	}
}