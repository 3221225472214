.contacts {
	&__grid {
		@include at-least-tablet {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}
	}

	&__item {
		display: block;
		font-size: 20px;
		position: relative;
		padding: 0 0 0 70px;
		margin-bottom: 40px;

		&:last-child {
			margin-bottom: 0;
		}


		>svg {
			display: block;
			width: 50px;
			height: 50px;

			@include absolute-center-vertical;
			left: 0;

		}

		@include at-least-tablet {
			text-align: center;
			padding: 0 20px;
			margin-bottom: 0;
			flex: 1;

			>svg {
				margin: 0 auto 30px auto;
				position: relative;
				top: 0;
				transform: translateY(0);
				left: auto;
			}
		}
	}
	
	&__map {
		height: 300px;
		background: $color-dark url("/front/images/contacts-map.jpg") center no-repeat;
		background-size: cover;
		position: relative;

		@include at-least-tablet{
			height: 380px;
		}

		&__link {
			display: block;
			width: 100px;
			height: 100px;
			@include absolute-center;
		}
	}

	&__richtext {
		overflow-x: auto;
		table {
			tr {
				td,th {
					padding: 10px;
					&:first-child {
						padding-left: 0;
					}
				}
			}
		}
	}
}