.burger {
	display: inline-block;
	position: relative;
	background: transparent;
	border: none;
	height: 40px;
	width: 44px;
	z-index: 100;
	float: right;

	span {
		display: block;
		height: 5px;
		width: 30px;
		background-color: $color-main;
		@include absolute-center-vertical;
		right: 0;
		transition: all $transition-timing $transition-animation;

		&:before, &:after {
			content: '';
			position: absolute;
			right: 0;
			display: block;
			height: 5px;
			width: 44px;
			background-color: $color-main;
			transition: all $transition-timing $transition-animation;
		}

		&:before {
			top: -14px;
		}

		&:after {
			bottom: -14px;
		}
	}

	&:hover {
		span {
			width: 44px;
		}
	}

	.open &{
		span {
			visibility: hidden;

			&:before, &:after {
				visibility: visible;
				background-color: white;
			}
			&:before {
				transform: rotate(-45deg);
				top: 0;
			}
			&:after {
				transform: rotate(45deg);
				bottom: 0;
			}
		}
	}
}

.header {
	position: fixed;
	top: 0;
	left:0;
	right: 0;
	z-index: 99;
	background-color: $color-black;
	padding: 110px 22px 40px 22px;
	min-height: 100vh;
	display: none;

	@include at-least-desktop {
		padding-top: 170px;
	}

	&.open {
		display: block;
	}

	&__btn {
		@include absolute-fullsize;
		left: 50%;
		height: auto;
		margin: 0 auto;
		padding-top: 25px;
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		z-index: 100;

		.lang {
			display: none;
		}

		@include at-least-desktop{
			padding-top: 65px;

			.lang {
				display: inline-block;
				position: absolute;
				right: 28px;
				top: 23px;
				z-index: 102;
			}
		}

		.logo {
			position: absolute;
		}

		&.dark {
			.main-logo {
				>svg {
					path:first-child {
						fill: black;

					}
				}
			}

			&.open {
				.main-logo {
					>svg {
						path:first-child {
							fill: white;

						}
					}

				}
			}
		}

	}

	&__menu {
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: 32px;
		line-height: 1.7;
		text-align: center;
		@include font-secondary;
		font-weight: 900;


		@include at-least-tablet {
			font-size: 46px;
		}

		@include at-least-desktop {
			font-size: 66px;
		}

		a {
			color: white;
			transition: all $transition-timing $transition-animation;
			&.active {
				color: $color-main;
				&:hover {
					color: $color-main;
				}
			}
			&:hover {
				color: $color-dark;
			}
		}

	}

	.lang {
		@include at-least-desktop {
			display: none;
		}
		&__menu {
			text-align: center;
			li {
				>a {
					color: white;
				}

				&.active {
					>a {
						color: $color-main;
					}
				}
			}
		}
	}

}

.lang {
	position: relative;

	&__menu {

		margin: 0;
		padding: 0;

		li {
			display: inline-block;

			/*@include at-least-desktop {
				display: block;
			}*/

			>a {
				padding: 10px 5px;
				display: block;
				color: $color-grey;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 12px;

				&:hover {
					color: $color-main;
				}

				@include at-least-desktop{
					padding: 5px;
				}
			}

			&.active {
				>a {
					color: $color-dark;
				}
			}
		}
	}
}


.main-logo {
	position: relative;
	display: none;
	z-index: 100;
	max-width: 366px;
	@include at-least-desktop(){
		display: block;
	}

	>svg {
		width: 365px;
	}

	.open & {
		path:first-child {
			fill: white;
		}
	}
}
.mobile-logo {
	display: block;
	z-index: 100;
	@include at-least-desktop(){
		display: none;
	}
	path:first-child {
		fill: $color-black;
	}
	.open & {
		path:first-child {
			fill: white;
		}
	}
}