.about {

	.lang-menu {
		display: none;

		@include at-least-desktop {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 40px;

			&__menu {
				transform: translateY(52px);
			}
		}
	}

	&__content {
		position: relative;
		max-width: 1000px;
		margin: auto;
		padding: 100px 22px 40px 22px;

		@include at-least-tablet {
			padding-top: 100px;
			margin-left: 20%;
			padding-right: 60px;
		}
	}

	&__block {
		max-width: 850px;
		padding: 40px 0;
		position: relative;
		z-index: 5;

		@include at-least-tablet {
			padding-top: 180px;
			padding-bottom: 0;

			&:last-child {
				padding-bottom: 180px;
			}

			&:nth-child(even) {
				margin-left: 20%;
			}

		}

		&__title {
			color: $color-main;
			font-size: 16px;
			font-weight: normal;
			margin-bottom: 40px;

		}

		&__highlighted {
			@include font-secondary;
			margin-bottom: 40px;
			@include at-least-tablet {
				font-size: 46px;
				font-weight: bold;
			}

		}

		&__content {
			margin-bottom: 40px;
			@include at-least-tablet {
				font-size: 20px;
			}

		}
	}

	&__svg {
		position: absolute;
		z-index: 4;
		top: 0;
		height: 100%;
		>svg {
			height: 100%;
			path {
				transition: all $transition-timing $transition-animation;
				stroke-dashoffset: 1000;
				stroke-dasharray: 1000;
			}
		}
	}
}