.btn {
	display: inline-block;
	line-height: 1;
	text-align: center;
	width: auto;


	&__green {

		font-size: 12px;
		font-weight: bold;
		padding: 12.2px 30px;
		border: 4.8px solid $color-main;
		color: $color-main;
		transition: all $transition-timing $transition-animation;
		position: relative;
		overflow: hidden;
		background-color: white;
		letter-spacing: 2px;
		text-transform: uppercase;

		&:hover {
			color: white;
			background-color: $color-main;
		}

	}
}