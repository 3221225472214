.services {

	padding-top: 50px;

	@include grid-media($neat-grid-tablet){
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@include grid-saver;
	}

	&__item {

		font-size: 20px;
		font-weight: 300;
		margin-bottom: $grid-gutter;

		img {
			height: 40px;
			margin: 0 auto 30px 0;
		}

		@include grid-media($neat-grid-tablet){
			@include grid-column(6);
			margin-bottom: 70px;
		}

		@include grid-media($neat-grid-desktop){
			@include grid-column(4);
		}
	}
}