@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&subset=cyrillic,cyrillic-ext,latin-ext');

@import url('https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre:400,500,700,900&subset=latin-ext');

*, *:before, *:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    position: relative;
    z-index: $z-body;
    line-height: 1.5;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    overflow-x: hidden;

    @include general-font-size;

    @include at-least-tablet {
        z-index: $z-body-tablet;
    }

    @include at-least-desktop {
        z-index: $z-body-desktop;
    }

    &.locked {
        height: 100%;
        overflow: hidden;
    }
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

p {
    margin: 0 0 20px 0;
}

/* fallback for svg sprite to be able tp style shadow root */

svg {
    path {
        stroke-width: inherit;
    }
}

main {
    position: relative;
}

.soc-links {
    width: 30px;
    display: none;
    @include at-least-tablet{
        display: block;
    }
    >a {
        @include at-least-tablet{
            display: block;
            +a {
                margin-top: 20px;
            }
        }
        &:hover {
            path {
                fill: $color-main;
            }
        }
    }

    path{
        fill: $color-black;
    }
    &.open {
        >a {
            path{
                fill: white;
            }
        }
    }
}

.container {
    @include container(1230px + $grid-gutter*2);
}

.container-small {
    @include container(1030px + $grid-gutter*2);
}

.wrap {
    padding: 60px 0;

    @include at-least-tablet {
        padding: 120px 0;
    }
}

.bg {
    &--light {
        background-color: $color-light;
    }

    &--black {
        background-color: $color-black;
        color: white;
    }
}

.pop-bg {
    display: none;
    @include fixed-all-sides();
    z-index: 101;
    background-color: rgba(white, 0.8);
    overflow-y: auto;
}

.js-popup {
    cursor: pointer;
}

.section-title {
    @include font-secondary;
    margin-bottom: 20px;
    font-size: 2em;

    @include at-least-tablet {
        font-size: 46px;
    }
}

.curtain {
    @include fixed-all-sides;
    background-color: white;
    z-index: 999;
    transition: all 2s $transition-animation;
    transform: translateX(0);

    @include at-least-tablet {
        transition: all 4s $transition-animation;
    }
    >svg {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        right: 100%;
    }

    &.open {
        transform: translateX(200vw);
    }
}