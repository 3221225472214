.team {

	&__grid {
		@include grid-media($neat-grid-desktop) {
			@include grid-container();
			@include grid-saver;
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__budy {
		margin-bottom: $grid-gutter;
		position: relative;

		@include grid-media($neat-grid-tablet){
			display: flex;
			align-items: center;
		}

		@include grid-media($neat-grid-desktop) {
			@include grid-column(6);
		}

		&__pic {
			@include aspect-ratio(1, 1);
			position: relative;
			width: 100%;
			max-width: 285px;
			background-color: $color-black;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: left center;
			//clip-path: polygon(0% 0%, 100% 0, 65% 50%, 100% 100%, 0% 100%);

			&:after {
				content: '';
				@include absolute-all-sides;
				background: url('/front/images/team-triangle.png') right center no-repeat;
				background-size: contain;
				z-index: 5;
			}

			@include at-least-tablet {
				&:hover {
					.team__budy__hover {
						opacity: 1;
					}
				}
			}
		}

		&__hover {
			opacity: 0;
			transition: opacity $transition-timing $transition-animation;
			@include absolute-all-sides;
			background-color: $color-black;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: left center;
			z-index: 4;
		}

		&__content {

			position: relative;
			z-index: 6;

			@include grid-media($neat-grid-tablet){
				margin-left: -20px;
			}
		}

		&__name {
			margin-bottom: 5px;

			@include at-least-tablet {
				font-size: 28px;
			}

		}

		&__job {
			margin-bottom: 25px;
			@include at-least-tablet {
				font-size: 20px;
			}

		}

	}
}

.team-card {

	display: none;
	background-color: $color-dark;
	color: white;
	max-width: 1230px;
	position: absolute;
	top: 20px;
	left: 20px;
	right: 20px;
	z-index: 102;
	font-size: 20px;

	@include at-least-tablet {
		@include absolute-center;
		width: 100%;
		right: 0;

	}

	.close {
		position: absolute;
		top: 0;
		right: 0;
		border: 0;
		background: transparent;
		padding: 20px;
		z-index: 102;
		>svg {
			width: 20px;
			height: 20px;
			color: white;
			pointer-events: none;
		}

		&:hover, &:focus {
			>svg {
				color: $color-main;
			}
		}
	}

	&__grid {
		margin: 0;
		width: 100%;
		display: block;
		padding-top: 60px;

		@include at-least-tablet {
			padding-top: 0;
			display: flex;
			align-items: flex-start;
		}
	}

	&__pic {
		flex: 2;
		@include aspect-ratio(1, 1);
		position: relative;
		width: 100%;
		background-color: $color-black;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left center;
		clip-path: polygon(0% 0%, 100% 0, 65% 50%, 100% 100%, 0% 100%);
	}

	&__content {
		margin-left: 0;
		padding: 40px;

		@include at-least-tablet{
			flex: 3;
			text-align: right;
			font-size: 20px;
		}
	}

	&__name {
		margin-bottom: 5px;

		@include at-least-tablet {
			font-size: 46px;
		}

	}

	&__job {
		margin-bottom: 25px;
		@include at-least-tablet {
			font-size: 24px;
		}

	}

	&__contact {
		position: relative;
		display: inline-block;
		padding-left: 50px;
		margin-top: 20px;
		margin-right: 40px;

		&:last-child {
			margin-right: 0;
		}

		>svg {
			width: 30px;
			height: 30px;
			@include absolute-center-vertical;
			left: 0;
		}
	}
}

.pic-triangle {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	> svg {
		height: 100%;
	}

}
