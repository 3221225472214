
h1, h2, h3, h4, h5 {
	margin: auto;
	@include font-secondary;
	font-weight: 900;
}

h1 {
	font-size: 40px;

	@include at-least-tablet {
		font-size: 50px;
	}

	@include at-least-desktop {
		font-size: 62px;
	}

}

h2 {
	font-size: 36px;

	@include at-least-tablet {
		font-size: 42px;
	}

}

h3 {
	font-size: 30px;
	@include at-least-tablet {
		font-size: 40px;
	}

}

h4 {
	font-size: 24px;
	@include at-least-tablet {
		font-size: 30px;
	}

}

h5 {
	font-size: 20px;
	@include at-least-tablet {
		font-size: 24px;
	}
}

a {

}

ul {

}

ol {

}

b, strong {
	letter-spacing: 2px;
}

blockquote {
	padding: 30px;
	border-left: 2px solid $color-light;

}

hr {

	border: 0;
	border-top: 1px solid $color-light;
	margin: $grid-gutter 0;
}

.uppercase {
	text-transform: uppercase;
}