/* AOS animation library */
@import "../../../node_modules/aos/dist/aos.css";

/* MAIN LIB */
@import "mixins-lib/collection";

/* all project specific */

@import "main/general";

@import "main/elements";

@import "main/blocks";

@import "main/templates";