.hero {
	position: relative;
	padding-top: 100px;

	@include at-least-tablet {
		min-height: 700px;
	}

	@include at-least-desktop {
		min-height: 800px;
		padding-top: 0;
	}

	.container, .container-small {
		margin-top: -320px;

		@include grid-media($neat-grid-tablet) {
			@include absolute-fullsize;
			left: 50%;
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			margin: 0 auto;
		}

		&__grid {
			@include grid-media($neat-grid-tablet) {
				display: flex;
				flex-wrap: nowrap;
				@include grid-saver;
			}
		}
	}

	.overlay {
		@include absolute-all-sides;
		background-color: rgba(black, 0.2);
		z-index: 2;
	}

	&__image {
		position: relative;
		overflow: hidden;
		height: 220px;
		max-width: 320px;
		background-color: $color-black;
		margin-right: 20px;
		z-index: 0;

		@include grid-media($neat-grid-tablet) {
			margin-right: 0;
			width: 60%;
			height: 700px;
			max-width: none;

			.error404 & {
				width: 40%;
			}
		}

		@include grid-media($neat-grid-desktop){
			height: 800px;
		}

		video {
			@include absolute-center-horizontal;
			top: 0;
			min-width: 100%;
			min-height: 100%;
			max-width: none;
			height: 100%;
		}

		>img {
			position: absolute;
			right: -2px;
			top: 0;
			bottom: 0;
			height: 100%;
			width: auto;
			z-index: 3;

			&.desktop {
				display: none;
				visibility: hidden;

				@include grid-media($neat-grid-tablet) {
					display: block;
					visibility: visible;
				}
			}

			&.mobile {
				display: block;
				visibility: visible;

				@include grid-media($neat-grid-tablet) {
					display: none;
					visibility: hidden;
				}
			}
		}

		/*> svg {
			position: absolute;
			right: -1px;
			top: 0;
			bottom: 0;
			height: 100%;

			&.desktop {
				display: none;

				@include grid-media($neat-grid-tablet) {
					display: block;
					width: 236px;
				}
				@include grid-media($neat-grid-desktop) {
					width: 268px;
				}
			}

			&.mobile {
				display: block;

				@include grid-media($neat-grid-tablet) {
					display: none;
				}
			}
		}*/
	}


	&__left {
		position: relative;
		padding-top: 100px;
		height: 320px;
		@include grid-media($neat-grid-tablet) {
			@include grid-column(7);
			height: 800px;

			.error404 & {
				@include grid-column(5);
			}
		}
	}

	&__right {
		position: relative;
		z-index: 4;
		@include grid-media($neat-grid-tablet) {
			@include grid-column(5);
			height: 700px;
			margin-top: 100px;

			.error404 & {
				@include grid-column(7);
			}
		}

		@include at-least-desktop {
			height: 800px;
			margin-top: 0;
		}

		&__content {
			padding-bottom: $grid-gutter;
			padding-top: 70px;
			text-align: right;
			position: relative;
			z-index: 5;

			@include grid-media($neat-grid-tablet) {
				padding-top: 0;
				@include absolute-center-vertical;
				padding-bottom: 0;
				right: 0;
				min-width: 460px;
			}

			@include grid-media($neat-grid-desktop){
				min-width: 600px;
			}
		}

		.btn + .btn {
			margin-left: 30px;
		}
	}

	&__main-title {
		font-size: 40px;
		@include font-secondary();
		font-weight: bold;
		margin-bottom: 20px;
		@include at-least-desktop {
			font-size: 58px;
			line-height: 80px;
		}
	}

	&__intro-title {
		font-weight: normal;
		font-size: 24px;
		margin-bottom: 40px;
		@include font-regular;
	}
}

.fr {
	.hero__right {
		.btn + .btn {
			margin-left: 5px;
		}
	}
}

.hero-slider {
	z-index: 1;

	&__item {

		position: relative;
		overflow: hidden;
		height: 220px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		@include grid-media($neat-grid-tablet) {
			height: 800px;
		}
	}
}
