
.triangle-zone {

	position: relative;
	overflow: hidden;

	@include grid-media($neat-grid-desktop) {
		display: flex;
	}

	&__left {
		position: relative;
		@include grid-media($neat-grid-desktop) {
			width: 60%;
			height: 502px;
			margin-left: 0;
		}
	}

	&__right {
		position: relative;
		height: 300px;
		background: $color-dark url("/front/images/contacts-map.jpg") center no-repeat;
		background-size: cover;
		z-index: 4;

		@include grid-media($neat-grid-desktop) {
			background-position-x: 43%;
			height: 502px;
			width: 40%;
		}

		> svg {
			display: none;

			@include grid-media($neat-grid-desktop) {
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				z-index: 2;
			}
		}
	}

	&__wrap {
		@extend .container-small;
		padding-top: $grid-gutter;
		padding-bottom: $grid-gutter;
		z-index: 3;

		@include grid-media($neat-grid-desktop) {
			@include absolute-center;
			width: 100%;
			margin: 0 auto;
		}
	}

	&__content {
		@include grid-media($neat-grid-desktop) {
			max-width: 500px;
		}
	}


	&__map-link {
		display: block;
		width: 70px;
		height: 70px;
		z-index: 4;

		@include absolute-center;

		@include at-least-desktop {
			transform: translate(0);
			width: 50%;
			left: auto;
			right: 10%;
			top: 45%;
		}
	}
}

.landing-contact {

	&__item {
		position: relative;
		font-size: 20px;
		padding-left: 80px;
		margin-top: 40px;

		> svg {
			width: 50px;
			height: 50px;
			@include absolute-center-vertical;
			left: 0;
		}

		&--no-icon {
			padding-left: 0;
		}
	}
}



