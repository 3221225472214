/* TRANSITIONS */
$transition-animation: cubic-bezier(0.22, 0.61, 0.36, 1);
$transition-timing   : .3s;

// How to use?
// @include transition;                                -> sets transition to `all` with default timing
// @include transition(background)                     -> sets transition to `background` with default timing
// @include transition(background color)               -> sets transition to `background` & `color` with default timings
// @include transition((background, color))            -> sets transition to `background` & `color` with default timings
// @include transition((background: .15s, color: .2s)) -> sets transition to `background` & `color` with given timings
@mixin transition( $attributes: () ) {
    @if length( $attributes ) >= 1 {

        $value: '';

        @each $attr, $time in $attributes {

            @if $time {
                $value: #{$value} #{$attr} #{$time};
            } @else {
                $value: #{$value} #{$attr} #{$transition-timing}
            }

            @if index( $attributes, $attr ) != length( $attributes ) {
                $value: '#{$value}, ';
            }
        }

        transition: #{$value} $transition-animation;
    } @else {
        transition: all .3s $transition-animation;
    }
}

@mixin transition-linear() {
    transition: all .2s;
}


/* POSITIONING */
@mixin absolute-center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-center-vertical() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin absolute-center-horizontal() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin absolute-fullsize() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin absolute-all-sides(){
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
@mixin fixed-all-sides(){
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

@mixin menu-ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;

        a {
            display: block;
        }
    }
}


/* OTHERS */
@mixin hide() {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
}

@mixin hr ($border-size,$border-style,$border-color) {
    border: 0;
    border-top: $border-size $border-style $border-color;
}

@mixin circle ($size, $border, $background-color, $color: ''){
    display: block;
    width: $size;
    height: $size;
    border-radius: 50%;
    border: $border;
    background-color: $background-color;

    @if $color != '' {
        color: $color;
    }
}

@mixin centered-cover-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

/* LAYOUT */

@function step($number){
    @return (10px * $number);
}

@mixin container( $local-max-width: $cont-max-width, $padding: true ) {
    position: relative;
    padding: 0 $cont-padding;;
    max-width: $local-max-width;
    margin-left: auto;
    margin-right: auto;

    @include at-least-tablet {
        padding: 0 $grid-gutter;
    }
}

