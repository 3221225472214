.page-links {
	background-color: $color-black;
	padding: 40px 0;
	@include at-least-tablet {
		padding: 70px 0 50px 0;
	}

	.container {

		@include at-least-tablet {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__item {
		display: inline-block;
		color: white;
		text-transform: uppercase;
		font-size: 14px;
		position: relative;
		padding: 0 30px 0 0;
		font-weight: bold;
		margin-bottom: 20px;
		letter-spacing: 2px;
		transition: all $transition-timing $transition-animation;

		@include at-least-tablet{
			&:last-child {
				margin-left: 100px;
			}
		}

		>svg {
			width: 21px;
			height: 20px;
			fill: white;
			@include absolute-center-vertical;
			right: 0;
		}

		&:hover {
			color:  $color-main;
			>svg {
				fill: $color-main
			}
		}
	}

	&--lighter {
		background-color: $color-dark;
	}
}