.footer {

	background-color: $color-light;
	padding: 40px 0;
	@include at-least-tablet {
		padding: 70px 0;
	}

	&__soc-links {
		text-align: center;

		@include at-least-tablet {
			display: flex;
			justify-content: center;
			align-items: center;
		}


		>a {
			display: block;
			color: $color-black;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: bold;
			padding: 0 0 15px 0;
			letter-spacing: 2px;
			transition: all $transition-timing $transition-animation;

			&:hover {
				color: $color-main;
			}

			@include at-least-tablet {
				padding: 0 15px 30px;
			}
		}
	}

	&__bottom {
		text-align: center;
		font-size: 14px;
	}


	&__copyrights {
		color: $color-grey;
		font-size: 12px;
	}

}