/* fonts */

@mixin font-regular {
	font-family: 'Montserrat', sans-serif;
}

@mixin font-secondary {
	font-family: 'Frank Ruhl Libre', serif;
}

/* font sizes */

@mixin general-font-size {

}

@mixin heading1 {

}

@mixin heading2 {
}

@mixin heading3 {

}

@mixin heading4 {

}


