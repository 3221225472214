@charset "UTF-8";
/// Creates a grid container with clearfix.
///
/// @group features
///
/// @name Grid container
///
/// @example scss
///   .element {
///     @include grid-container;
///   }
///
/// @example css
///   .element::after {
///     clear: both;
///     content: "";
///     display: block;
///   }

@mixin grid-container($grid: $neat-grid) {
    @if $grid != $neat-grid {
        @warn "`grid-container` does not use grid propertes. Custom grids do not need to be passed in to this mixin . ";
    }

    &::after {
        clear: both;
        content: "";
        display: block;
    }
}
