.history {
	&__hero {
		padding: 100px 0 60px 0;

		@include at-least-desktop {
			padding: 170px 0 120px 0;
		}

		h1 {
			text-align: right;
			padding-left: 60px;

			@include at-least-desktop{
				padding-right: 100px;
			}
		}

	}

	&__filters {
		@include menu-ul;
		text-align: right;
		@include at-least-desktop{
			padding-right: 100px;
			margin-top: 60px;
		}

		a {
			padding: 5px 10px;
			font-size: 12px;
			font-weight: bold;
			color: $color-grey;

			&:hover {
				color: $color-main;
			}

			&.active {
				color: $color-black;
			}
		}
	}

	&__table {

		overflow-x: auto;
		&__head {
			min-width: 650px;
			font-weight: bold;
			display: flex;
			border-bottom: 3px solid black;

			@include at-least-desktop{
				font-size: 20px;
			}

			>div{
				padding: 15px;

				@include at-least-desktop{
					padding: 15px 30px;
				}


				&:first-child {
					flex: 3;
				}
				&:nth-child(2){
					flex: 1;
				}
				&:nth-child(3){
					flex: 2;
				}
			}
		}

		&__body {
			min-width: 650px;
		}
	}

	&__event {
		display: flex;
		color: $color-black;
		> div {
			padding: 15px;

			@include at-least-desktop{
				padding: 26px 30px;
			}

			&:first-child {
				flex: 3;
			}
			&:nth-child(2) {
				flex: 1;
			}
			&:nth-child(3) {
				flex: 2;
			}
		}
	}
}

a.history__event {
	&:hover {
		background-color: $color-light;
	}
}