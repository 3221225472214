@keyframes slide-in {
	0% {
		transform: translateX(-50vw);
	}
	
	40% {
		transform: translateX(10vw);
	}
	70% {
		transform: translateX(-5vw);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes slide-down {
	
}