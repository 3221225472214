.projects {
	@include grid-media($neat-grid-tablet){
		@include grid-container();
		@include grid-saver;
		display: flex;
		flex-wrap: wrap;


		.card {
			@include grid-column(6);
		}
	}

	&__more {
		display: block;
		padding: 5px;
		margin: 25px auto 0 auto;
		background: transparent;
		border: none;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 14px;
		transform: all $transition-timing $transition-animation;
		color: $color-black;

		&:hover, &:focus {
			color: $color-main;
		}

		&:focus, &:active {
			outline: none;
		}

		>svg {
			display: block;
			height: 13px;
			width: 20px;
			margin: 15px auto 0 auto;
		}
	}

	&__statistics {
		color: white;
		padding-top: 54px;
		padding-right: 80px;

		@include grid-media($neat-grid-desktop){
			padding-top: 0;
			padding-right: 248px;
			@include absolute-center-vertical;
		}
	}

	&__numbers {

		span {
			display: block;
			@include font-secondary;
			font-weight: 900;
			font-size: 30px;
			line-height: 1;
		}

		@include grid-media($neat-grid-desktop){
			font-size: 24px;
			margin-bottom: 70px;
			&:last-child {
				margin-bottom: 0;
			}

			span {
				font-size: 82px;
			}
		}
	}
}

.card {
	margin-bottom: $grid-gutter;

	&__img {
		display: block;
		position: relative;
		overflow: hidden;
		@include aspect-ratio(456,304);
		margin-bottom: 40px;

		img {
			@include absolute-center;
			width: auto;
			height: 100%;
			min-width: 100%;
			max-width: none;
		}

	}

	&__overlay {
		@include absolute-all-sides;
		transition: all $transition-timing $transition-animation;

		&:hover {
			background-color: rgba(black, 0.3);
		}
	}

	&__title {
		@include font-secondary;
		font-weight: 900;
		font-size: 20px;
		color: $color-black;
		transition: all $transition-timing $transition-animation;

		@include at-least-tablet {
			font-size: 28px;
		}

		&:hover {
			color: $color-main;
		}
	}
}


.project {
	&__hero {
		min-height: 300px;
		position: relative;
		padding: 100px 0 60px 0;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@include at-least-desktop {
			min-height: 540px;
			padding: 170px 0 120px 0;
		}
		h1 {
			@include font-secondary;
			font-weight: 900;
			padding-left: 60px;
		}

		.overlay {
			@include absolute-all-sides;
			background-color: rgba(white,0.9);
		}

		.container {
			text-align: right;
		}
	}

	&__back {

		color: $color-main;

		&:hover {
			color: $color-black;
		}

		@include at-least-tablet {
			position: absolute;
			left: 32px;
		}
		>svg {
			width: 44px;
			height: 31px;
			transition: all $transition-timing $transition-animation;
		}
	}

	&__content {
		max-width: 938px;

		@include at-least-tablet {
			font-size: 24px;
			font-weight: 300;
		}
	}

	&__heading {
		font-size: 30px;
		@include at-least-tablet {
			font-size: 46px;
		}
	}

	&__share {
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		letter-spacing: 2px;

		@include at-least-tablet {
			margin-top: 80px;
		}

		span {
			padding-right: 10px;
		}

		a {
			display: inline-block;
			padding: 10px;
			color: $color-black;
			>svg {
				width: 16px;
				height: 16px;
				transition: all $transition-timing $transition-animation;
			}

			&:hover {
				color: $color-main;
			}
		}
	}

	&__slider {
		margin-top: 70px;
		.slick-slide {
			>div{
				padding-right: 30px;
				img {
					max-height: 495px;
					width: auto!important;
				}
			}
		}

		.slick-dots {
			position: relative;
			z-index: 10;
			list-style: none;
			margin: 50px 0 0 0;
			padding: 0;
			display: flex;

			li {
				display: inline-block;
				width: 100%;
				button {
					display: block;
					width: 100%;
					text-indent: -9999px;
					overflow: hidden;
					border: 0;
					padding: 0;
					height: 3px;
					background-color: $color-dark;

					&:focus, &:active {
						outline: none;
						background-color: $color-main;
					}
				}

				&.slick-active {
					button {
						background-color: $color-light;
					}
				}
			}
		}

		.slick-arrow {
			cursor: pointer;

			.icon {
				width: 44px;
				height: 31px;
				transition: all $transition-timing $transition-animation;

				.bg--black & {
					color: white;
				}
			}

			&:hover {
				.icon {
					color: $color-main;
				}
			}

			@include at-least-desktop {
				@include absolute-center-vertical;

				&.prev {
					left: -80px;
				}

				&.next {
					right: -80px;
				}
			}
			&.slick-disabled {
				opacity: 0;
			}

		}
	}
}