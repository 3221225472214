
/* BREAKPOINTS */

$tablet: 840px;
$desktop: 1380px;
$large: 1600px;
//$tablet: new-breakpoint(min-width $tablet-bp 8) !global; // Use 8 column grid
//$desktop: new-breakpoint(min-width $desktop-bp 12) !global; // Use 12 column grid
$z-body: 1;
$z-body-tablet: 2;
$z-body-desktop: 3;


/* LAYOUT CONFIGURATION */

// Max width used for grid
$max-width: 1160px !global;
// Max width used for container - basically it`s $max-width + $cont-padding * 2
$cont-max-width: 1200px !global;
$cont-padding: 20px !global;
// You can set paddings for each breakpoint as well here...
// $cont-padding-tablet: rem(30) !global;
// $cont-padding-desktop: rem(40) !global;

/* GRID CONFIG */

// Default columns count
$grid-columns: 6 !global;
// Here you can define column & gutter size
// 3 & 1 is proportions. It means column size is 3x greater than gutter
$column: modular-scale(3, 1em, $golden) !default;
$gutter: modular-scale(1, 1em, $golden) !default;



/* new grids  configuration */

$grid-gutter: 32px;


$neat-grid: (
		columns: 12,
		gutter: $grid-gutter/2,
);

$neat-grid-tablet: (
		media: $tablet,
		columns: 12,
		gutter: $grid-gutter
);

$neat-grid-desktop: (
		media: $desktop,
		columns: 12,
		gutter: $grid-gutter
);

@mixin at-least-tablet {
	@media(min-width: $tablet) {
		@content;
	}
}

@mixin at-least-desktop {
	@media(min-width: $desktop) {
		@content;
	}
}

@mixin at-least-large {
	@media(min-width: $large) {
		@content;
	}
}

@mixin column-full {
	@include grid-column(6);
	@include at-least-tablet {
		@include grid-column(8);
	}
	@include at-least-desktop {
		@include grid-column(12);
	}
}

@mixin column-centered() {
	float: none;
	margin-left: auto !important;
	margin-right: auto !important;
}

@mixin column-readable {
	@include grid-column(6);
	@include at-least-tablet {
		@include grid-column(6);
		@include column-centered();
	}
	@include at-least-desktop {
		@include grid-column(8);
		@include column-centered();
	}
}


@mixin grid-saver() {
	margin-left: - $grid-gutter;
	margin-right: - $grid-gutter;
}